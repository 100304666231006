import * as React from "react"
import PageLayout from "../components/PageLayout"

const NotFoundPage = () => (
    <PageLayout>
        <div className="w-0 h-40 md:h-32" />
        <div className="w-0 h-40 md:h-32" />
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <div className="w-0 h-40 md:h-32" />
        <div className="w-0 h-40 md:h-32" />
    </PageLayout>
)

export default NotFoundPage
